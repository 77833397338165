import { getRouteApi } from '@tanstack/react-router'
import { takeRight } from 'lodash'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Cancel from '@/assets/icons/cancel.svg?react'
import SendLink from '@/assets/icons/send-link.svg?react'
import Unlock from '@/assets/icons/unlock.svg?react'
import Button from '@/components/Button/Button'
import ButtonIcon from '@/components/ButtonIcon/ButtonIcon'
import LinkText from '@/components/LinkText/LinkText'
import Table, { type Column } from '@/components/Table/Table'
import Tag from '@/components/Tag/Tag'
import Tooltip from '@/components/Tooltip/Tooltip'
import BasicLayout from '@/components/common/BasicLayout/BasicLayout'
import Filters, { type Filter } from '@/components/common/Filters/Filters'
import TruncateElementsList from '@/components/common/TruncateElementsList'
import useRowSelection from '@/hooks/useRowSelection'
import { toast } from '@/hooks/useToast'
import useAuthStore from '@/store/useAuthStore'
import type { UserStatus } from '@/types/user-status'
import { userStatusTags } from '@/utils/user-status-tags'

import styles from './UsersListView.module.scss'
import ConfirmModal from '../../../components/ConfirmModal'
import type { UsersFiltersKeys } from '../constants/users-filters'
import useActivateUser from '../mutations/useActivateUser'
import useBlockUser from '../mutations/useBlockUser'
import useSendActivationUserLink, {
  type ActivateLinkResponse
} from '../mutations/useSendUserActivationLink'
import useAllUsersCount from '../queries/useAllUsersCount'
import useUsers, { type User } from '../queries/useUsers'
import useUsersFilters from '../queries/useUsersFilters'
import useUsersIds from '../queries/useUsersIds'
import { getColorByProfile, getLabelByProfile } from '../utils/getProfileTag'
import { getUserStatusTooltip } from '../utils/getUserStatusTooltip'

const routeApi = getRouteApi('/_auth/users')

const UsersListView = () => {
  const { t } = useTranslation(['users'])
  const { id, page, pageSize, status, phoneNumber, email, profile } =
    routeApi.useSearch()

  const navigate = routeApi.useNavigate()

  const {
    data: users,
    isLoading: isUsersLoading,
    refetch
  } = useUsers({
    id,
    page,
    pageSize,
    status,
    phoneNumber,
    email,
    profile
  })

  const { data: filters, isLoading: isFiltersLading } = useUsersFilters()

  const { data: allUsersIds, isLoading: isAllUsersLoading } = useUsersIds({
    id,
    status,
    phoneNumber,
    email,
    profile
  })

  const { data: allUsersCount } = useAllUsersCount()

  const selection = useRowSelection({
    count: allUsersIds?.count || 0,
    itemsToSelect: allUsersIds?.list || []
  })

  const { user } = useAuthStore()

  const handleBlock = () => {
    if (!!currentUser) blockUser({ id: currentUser?.id })
  }

  const [isExpandedProfiles, setIsExpandedProfiles] = useState(false)
  const [openBlockConfirmModal, setOpenBlockConfirmModal] = useState(false)
  const [openActivateConfirmModal, setOpenActivateConfirmModal] =
    useState(false)

  const [openActivationLinkModal, setOpenActivationLinkModal] = useState(false)
  const [currentUser, setCurrentUser] = useState<User | null>()

  const handleActivate = () => {
    if (!!currentUser) activateUser({ id: currentUser?.id })
  }

  const closeBlockConfirmModal = () => {
    refetch()
    setOpenBlockConfirmModal(false)
    setCurrentUser(null)
  }

  const { mutate: blockUser, isPending: isBlockUserLoading } = useBlockUser({
    onSuccess: () => {
      toast({
        variant: 'success',
        title: t('toast.user-blocked', { NAME: currentUser?.name })
      })
      closeBlockConfirmModal()
    },
    onError: () => {
      toast({
        variant: 'error',
        title: t('toast.user-blocked-failed', {
          NAME: currentUser?.name
        })
      })
      closeBlockConfirmModal()
    }
  })

  const closeActivateConfirmModal = () => {
    refetch()
    setOpenActivateConfirmModal(false)
    setCurrentUser(null)
  }

  const { mutate: activateUser, isPending: isActivateUserLoading } =
    useActivateUser({
      onSuccess: () => {
        toast({
          variant: 'success',
          title: t('toast.user-activate', {
            NAME: currentUser?.name
          })
        })
        closeActivateConfirmModal()
      },
      onError: () => {
        toast({
          variant: 'error',
          title: t('toast.user-activate', {
            NAME: currentUser?.name
          })
        })
        closeActivateConfirmModal()
      }
    })

  const { isPending, mutate: sendActivationLink } = useSendActivationUserLink({
    onSuccess: (data?: ActivateLinkResponse) => {
      if (data?.successfullySent) {
        if (currentUser?.id) {
          toast({
            variant: 'success',
            title: t('toast.successfully-send-activation-link')
          })
        } else {
          toast({
            variant: 'success',
            title: t('toast.successfully-send-activation-links', {
              NUMBER: data?.successfullySent
            })
          })
        }
      }
      if (data?.unsuccessfullySent) {
        toast({
          variant: 'error',
          title: t('toast.failed-send-activation-links', {
            NUMBER: data.unsuccessfullySent
          })
        })
      }
      refetch()
      setOpenActivationLinkModal(false)
      setCurrentUser(null)
      selection.clearSelection()
    }
  })

  const handleSendActivationLink = () => {
    const ids = currentUser?.id ? [currentUser?.id] : selection.selectedItems

    sendActivationLink({
      ids
    })
  }

  const actions = (row: User) =>
    user?.isSuperAdmin
      ? [
          {
            text: (
              <>
                <SendLink className={styles.icon} />
                {row.status === 'inactive'
                  ? t('button.send-activation-link')
                  : t('button.resend-activation-link')}
              </>
            ),
            hidden: row.status === 'blocked',
            onClick: (item: User) => {
              setCurrentUser(item)
              setOpenActivationLinkModal(true)
            }
          },
          {
            text: (
              <>
                <Cancel className={styles.icon} />
                {t('button.block-user')}
              </>
            ),
            hidden:
              row.status === 'blocked' ||
              row.status === 'inactive' ||
              row.id === user.id,
            onClick: (item: User) => {
              setCurrentUser(item)
              setOpenBlockConfirmModal(true)
            }
          },
          {
            text: (
              <>
                <Unlock className={styles.icon} />
                {t('button.activate-user')}
              </>
            ),
            hidden:
              row.status === 'active' ||
              row.status === 'inactive' ||
              row.id === user.id,
            onClick: (item: User) => {
              setCurrentUser(item)
              setOpenActivateConfirmModal(true)
            }
          }
        ].filter(item => !item.hidden)
      : []

  const handleChangeFilter = (
    key: UsersFiltersKeys,
    value?: string | string[]
  ) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        page: 1,
        [key]: value
      })
    })
  }

  const filterItems: Filter[] = [
    {
      variant: 'multiselect',
      label: t('label.name'),
      filterProps: {
        id: 'name-filter',
        options: filters?.idOptions || [],
        loading: isFiltersLading,
        multiple: true,
        value: id,
        placeholder: t('label.name'),
        onChange: value => handleChangeFilter('id', value)
      }
    },
    {
      variant: 'multiselect',
      label: t('label.role'),
      filterProps: {
        id: 'role-filter',
        options: filters?.profileOptions || [],
        loading: isFiltersLading,
        multiple: true,
        value: profile,
        placeholder: t('label.role'),
        onChange: value => handleChangeFilter('profile', value)
      }
    },
    {
      variant: 'multiselect',
      label: t('label.email'),
      filterProps: {
        id: 'email-filter',
        options: filters?.emailOptions || [],
        loading: isFiltersLading,
        multiple: true,
        value: email,
        placeholder: t('label.email'),
        onChange: value => handleChangeFilter('email', value)
      }
    },
    {
      variant: 'multiselect',
      label: t('label.phone-number'),
      filterProps: {
        id: 'phone-number-filter',
        options: filters?.phoneNumberOptions || [],
        loading: isFiltersLading,
        multiple: true,
        value: phoneNumber,
        placeholder: t('label.phone-number'),
        onChange: value => handleChangeFilter('phoneNumber', value)
      }
    },
    {
      variant: 'multiselect',
      label: t('label.status'),
      filterProps: {
        id: 'status-filter',
        options: filters?.statusOptions || [],
        loading: isFiltersLading,
        multiple: true,
        value: status,
        placeholder: t('label.status'),
        onChange: value => handleChangeFilter('status', value)
      }
    }
  ]

  const tableColumns: Column<User>[] = [
    {
      key: 'name',
      title: t('header.name'),
      width: 200,
      render: (value: User) => (
        <LinkText
          to="/users/$userId/details"
          params={{
            userId: value.id
          }}
        >
          {value.name}
        </LinkText>
      )
    },
    {
      dataIndex: 'profiles',
      key: 'profiles',
      title: t('header.role'),
      width: 200,
      render: (value: string[]) => {
        if (!value.length) {
          return (
            <Tag
              variant="with-background"
              className={styles.profileTag}
              label={t('text.user')}
              color={getColorByProfile('User')}
            />
          )
        }

        return (
          <TruncateElementsList
            expanded={isExpandedProfiles}
            onExpandedChange={setIsExpandedProfiles}
            elipsis={hiddenItemsCount => (
              <Tooltip
                text={takeRight(value, hiddenItemsCount).join(', ')}
                trigger={
                  <Tag
                    color="white"
                    variant="with-background"
                    label={`+${hiddenItemsCount}`}
                    onClick={() => {
                      setIsExpandedProfiles(true)
                    }}
                  />
                }
              />
            )}
          >
            {value.map((userProfile, index) => (
              <Tag
                variant="with-background"
                className={styles.profileTag}
                key={index}
                label={getLabelByProfile(userProfile)}
                color={getColorByProfile(userProfile)}
              />
            ))}
          </TruncateElementsList>
        )
      }
    },
    {
      dataIndex: 'email',
      key: 'email',
      title: t('header.email'),
      width: 200
    },
    {
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: 200,
      title: t('header.phone-number')
    },
    {
      dataIndex: 'status',
      key: 'status',
      cellDataTestId: 'status',
      title: t('header.status'),
      width: 70,
      render: (value: UserStatus) => (
        <Tooltip
          trigger={<Tag {...userStatusTags[value]} />}
          text={getUserStatusTooltip(value)}
        />
      )
    }
  ]

  const handleChangePage = (value: number) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        page: value
      })
    })
  }

  const handleChangePageSize = (value: number) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        pageSize: value
      })
    })
  }

  const handleClearAll = () => {
    navigate({
      search: {}
    })
  }

  const isSomeFilterSelected = filterItems.some(
    filter => filter.filterProps.value
  )

  return (
    <BasicLayout
      header={t('header.teachers-and-users')}
      moduleName={t('header.administration', { ns: 'common' })}
    >
      <Filters
        filters={filterItems}
        onClearAll={handleClearAll}
        disabledClearAllButton={!isSomeFilterSelected}
      />
      <Table
        id="users-table"
        isLoading={isUsersLoading || isAllUsersLoading}
        data={users?.list || []}
        actions={actions}
        columns={tableColumns}
        pagination={{
          count: users?.count,
          pageSize,
          page
        }}
        rowSelection={
          user?.isSuperAdmin
            ? {
                selectedItems: selection.selectedItems,
                allCheckboxValue: selection.getSelectAllCheckboxValue(),
                count: users?.count || 0,
                labelHeader: t('label.select-all-users'),
                labelItem: (row: User) =>
                  t('label.select-user', { USER: row.name }),
                onSelect: selection.onSelect,
                onSelectAll: selection.onSelectAll
              }
            : undefined
        }
        onChangePageSize={handleChangePageSize}
        onChangePage={handleChangePage}
        topBar={
          user?.isSuperAdmin ? (
            <>
              <div className={styles.topBar}>
                {selection.selectedItems.length ? (
                  <Tooltip
                    text={t('button.send-activation-link')}
                    trigger={
                      <ButtonIcon
                        variant="tertiary"
                        size="small"
                        onClick={() => setOpenActivationLinkModal(true)}
                        ariaLabel={t('button.send-activation-link')}
                      >
                        <SendLink />
                      </ButtonIcon>
                    }
                  />
                ) : null}
                <span className={styles.topBarText}>
                  {t('help.selected-text', {
                    SELECTED_COUNT: selection.selectedItems.length,
                    ALL_COUNT: allUsersCount || 0
                  })}
                </span>
              </div>
            </>
          ) : null
        }
      />

      <ConfirmModal
        id="activation-link-modal"
        header={t('header.send-activation-link')}
        subheader={
          currentUser?.id
            ? t('help.send-activation-link-single', {
                NAME: currentUser?.name
              })
            : t('help.send-activation-link-bulk', {
                NUMBER: selection.selectedItems.length
              })
        }
        confirmButton={
          <Button
            variant="primary"
            onClick={handleSendActivationLink}
            loading={isPending}
          >
            {t('button.send')}
          </Button>
        }
        open={openActivationLinkModal}
        onOpenChange={setOpenActivationLinkModal}
      />

      <ConfirmModal
        id="blocked-modal"
        header={t('header.block-user')}
        subheader={t('help.want-to-block-user', {
          NAME: currentUser?.name
        })}
        confirmButton={
          <Button
            variant="danger"
            onClick={handleBlock}
            loading={isBlockUserLoading}
          >
            {t('button.block')}
          </Button>
        }
        open={openBlockConfirmModal}
        onOpenChange={setOpenBlockConfirmModal}
      />

      <ConfirmModal
        id="activate-modal"
        header={t('header.activate-user')}
        subheader={t('help.want-to-activate-user', {
          NAME: currentUser?.name
        })}
        confirmButton={
          <Button onClick={handleActivate} loading={isActivateUserLoading}>
            {t('button.activate')}
          </Button>
        }
        open={openActivateConfirmModal}
        onOpenChange={setOpenActivateConfirmModal}
      />
    </BasicLayout>
  )
}

export default UsersListView
