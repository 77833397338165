import { getRouteApi } from '@tanstack/react-router'
import { debounce } from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Cancel from '@/assets/icons/cancel.svg?react'
import SendLink from '@/assets/icons/send-link.svg?react'
import Unlock from '@/assets/icons/unlock.svg?react'
import Button from '@/components/Button/Button'
import ButtonIcon from '@/components/ButtonIcon/ButtonIcon'
import ConfirmModal from '@/components/ConfirmModal'
import LinkText from '@/components/LinkText/LinkText'
import Table, { type Column } from '@/components/Table/Table'
import Tag from '@/components/Tag/Tag'
import Tooltip from '@/components/Tooltip/Tooltip'
import Filters, { type Filter } from '@/components/common/Filters/Filters'
import useRowSelection from '@/hooks/useRowSelection'
import { toast } from '@/hooks/useToast'
import useClasses from '@/queries/useClasses'
import useStudents, { type Student } from '@/queries/useStudents'
import useTeachers from '@/queries/useTeachers'
import useYears from '@/queries/useYears'
import useAuthStore from '@/store/useAuthStore'
import { type UserStatus } from '@/types/user-status'
import { addUnassignedFilterOption } from '@/utils/add-unassigned-filter-option'
import { userStatusTags } from '@/utils/user-status-tags'

import styles from './StudentsView.module.scss'
import ActivationLinkModal, {
  type ActivationLinkModalVariant
} from '../components/ActivationLinkModal'
import type { StudentsFiltersKey } from '../constants/students-filters'
import useActivateStudent from '../mutations/useActivateStudent'
import useBlockStudent from '../mutations/useBlockStudent'
import useParents from '../queries/useParents'
import useStatuses from '../queries/useStatuses'
import useStudentsAll from '../queries/useStudentsAll'
import useStudentsAllCount from '../queries/useStudentsAllCount'
import { getStudentStatusTootlipText } from '../utils/statuses'

const routeApi = getRouteApi('/_auth/students-and-classes/students')

const StudentsView = () => {
  const { t } = useTranslation(['students'])

  const { user } = useAuthStore()

  const { page, pageSize, search, year, classId, tutor, parent, status } =
    routeApi.useSearch()
  const navigate = routeApi.useNavigate()

  const [openBlockConfirmModal, setOpenBlockConfirmModal] = useState(false)
  const [openActivateConfirmModal, setOpenActivateConfirmModal] =
    useState(false)
  const [currentStudent, setCurrentStudent] = useState<Student | null>()
  const [openActivationLinkModal, setOpenActivationLinkModal] = useState(false)
  const [activationLinkModalVariant, setActivationLinkModalVariant] =
    useState<ActivationLinkModalVariant>('bulk')

  const [searchValue, setSearchValue] = useState(search)

  const {
    data: students,
    isFetching: isStudentsLoading,
    refetch
  } = useStudents({
    pageSize,
    page,
    search,
    year,
    tutor,
    parent,
    status,
    classId,
    fetchKeys: {
      id: true,
      full_name: true,
      year: true,
      student_class: true,
      tutor: true,
      parents: true,
      status: true
    }
  })

  const { data: allStudents } = useStudentsAll({
    search,
    year,
    tutor,
    parent,
    status,
    classId
  })

  const { data: allStudentCount } = useStudentsAllCount()

  const { data: statuses, isLoading: isStatusesLoading } = useStatuses()
  const { data: years, isLoading: isYearsLoading } = useYears()
  const { data: parents, isLoading: isParentsLoading } = useParents()
  const { data: classes, isLoading: isClassesLoading } = useClasses()
  const { data: teachers, isLoading: isTeachersLoading } = useTeachers({
    onlyTutor: true
  })

  const changeFilter = (key: StudentsFiltersKey, value?: string | string[]) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        page: 1,
        [key]: value
      })
    })
  }

  const handleClearAll = () => {
    navigate({
      search: {}
    })
  }

  const selection = useRowSelection({
    count: allStudentCount || 0,
    itemsToSelect: allStudents?.list || []
  })

  const closeBlockConfirmModal = () => {
    refetch()
    setOpenBlockConfirmModal(false)
    setCurrentStudent(null)
  }

  const { mutate: blockStudent } = useBlockStudent({
    onSuccess: () => {
      toast({
        variant: 'success',
        title: t('toast.student-blocked', { NAME: currentStudent?.fullName })
      })
      closeBlockConfirmModal()
    },
    onError: () => {
      toast({
        variant: 'error',
        title: t('toast.student-blocked-failed', {
          NAME: currentStudent?.fullName
        })
      })
      closeBlockConfirmModal()
    }
  })

  const closeActivateConfirmModal = () => {
    refetch()
    setOpenActivateConfirmModal(false)
    setCurrentStudent(null)
  }

  const { mutate: activateStudent } = useActivateStudent({
    onSuccess: () => {
      toast({
        variant: 'success',
        title: t('toast.student-activate', {
          NAME: currentStudent?.fullName
        })
      })
      closeActivateConfirmModal()
    },
    onError: () => {
      toast({
        variant: 'error',
        title: t('toast.student-activate', {
          NAME: currentStudent?.fullName
        })
      })
      closeActivateConfirmModal()
    }
  })

  const tableColumns: Column<Student>[] = [
    {
      key: 'student',
      title: t('header.student'),
      cellDataTestId: 'student',
      width: 200,
      render: (value: Student) => (
        <LinkText
          to="/students-and-classes/students/$studentId/details"
          params={{
            studentId: value.id
          }}
        >
          {value.fullName}
        </LinkText>
      )
    },
    {
      dataIndex: 'year',
      key: 'year',
      title: t('header.year'),
      cellDataTestId: 'year',
      width: 80,
      render: (yearName: string) =>
        yearName ? (
          yearName
        ) : (
          <span className={styles.textUnassigned}>{t('text.unassigned')}</span>
        )
    },
    {
      key: 'className',
      title: t('header.class'),
      cellDataTestId: 'class-name',
      width: 120,
      render: (value: Student) =>
        value.studentClass ? (
          <LinkText
            to="/students-and-classes/classes/$classId/details"
            params={{
              classId: value.studentClass.id
            }}
          >
            <div data-test-id="class">{value.studentClass.name}</div>
          </LinkText>
        ) : (
          <span className={styles.textUnassigned}>{t('text.unassigned')}</span>
        )
    },
    {
      cellDataTestId: 'tutor',
      key: 'tutor',
      width: 200,
      title: t('header.tutor'),
      render: (value: Student) =>
        value.tutor ? (
          <LinkText
            to="/users/$userId/details"
            params={{
              userId: value.tutor.id
            }}
          >
            {value.tutor.fullName}
          </LinkText>
        ) : (
          <span className={styles.textUnassigned}>{t('text.unassigned')}</span>
        )
    },
    {
      dataIndex: 'parents',
      cellDataTestId: 'parents',
      key: 'parents',
      title: t('header.guardians'),
      width: 200,
      render: (value: { fullName: string }[]) => {
        const parentsText = value
          ? value.map(item => item.fullName).join(', ')
          : ''
        return (
          <>
            {parentsText ? (
              <Tooltip
                renderTriggerAsChild
                trigger={
                  <div tabIndex={0} role="button">
                    <span className={styles.parents}>{parentsText}</span>
                  </div>
                }
                text={parentsText}
              />
            ) : (
              <span className={styles.textUnassigned}>
                {t('text.unassigned')}
              </span>
            )}
          </>
        )
      }
    },
    {
      dataIndex: 'status',
      key: 'status',
      cellDataTestId: 'status',
      title: t('header.status'),
      width: 80,
      render: (value: UserStatus) => (
        <Tooltip
          trigger={<Tag {...userStatusTags[value]} />}
          text={getStudentStatusTootlipText(value)}
        />
      )
    }
  ]

  const handleChangePage = (value: number) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        page: value
      })
    })
  }

  const handleChangePageSize = (value: number) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        pageSize: value
      })
    })
  }

  const handleBlock = () => {
    if (!!currentStudent) blockStudent({ id: currentStudent?.id })
  }

  const handleActivate = () => {
    if (!!currentStudent) activateStudent({ id: currentStudent?.id })
  }

  const actions = (row: Student) =>
    user?.isSuperAdmin
      ? [
          {
            text: (
              <>
                <SendLink className={styles.icon} />
                {row.status === 'active'
                  ? t('button.resend-activation-link')
                  : t('button.send-activation-link')}
              </>
            ),
            hidden: row.status === 'blocked',
            onClick: (item: Student) => {
              setCurrentStudent(item)
              setActivationLinkModalVariant('single')
              setOpenActivationLinkModal(true)
            }
          },
          {
            text: (
              <>
                <Cancel className={styles.icon} />
                {t('button.block-student')}
              </>
            ),
            hidden: row.status === 'blocked' || row.status === 'inactive',
            onClick: (item: Student) => {
              setCurrentStudent(item)
              setOpenBlockConfirmModal(true)
            }
          },
          {
            text: (
              <>
                <Unlock className={styles.icon} />
                {t('button.activate-student')}
              </>
            ),
            hidden: row.status === 'active' || row.status === 'inactive',
            onClick: (item: Student) => {
              setCurrentStudent(item)
              setOpenActivateConfirmModal(true)
            }
          }
        ].filter(item => !item.hidden)
      : []

  const debouncedSearch = debounce(
    (value: string) => changeFilter('search', value),
    300
  )

  useEffect(() => {
    debouncedSearch(searchValue || '')
    return () => {
      debouncedSearch.cancel()
    }
  }, [debouncedSearch, searchValue])

  const filters: Filter[] = [
    {
      label: t('label.search'),
      variant: 'input',
      filterProps: {
        id: 'search',
        value: searchValue,
        type: 'search',
        dataTestId: 'search',
        placeholder: t('label.student'),
        onChange: value => setSearchValue(value)
      }
    },
    {
      label: t('label.year'),
      variant: 'multiselect',
      filterProps: {
        id: 'year',
        multiple: true,
        loading: isYearsLoading,
        options: addUnassignedFilterOption(years?.options || []),
        value: year,
        placeholder: t('label.year'),
        onChange: value => changeFilter('year', value)
      }
    },
    {
      label: t('label.class'),
      variant: 'multiselect',
      filterProps: {
        id: 'class',
        multiple: true,
        loading: isClassesLoading,
        options: addUnassignedFilterOption(classes?.options || []),
        value: classId,
        placeholder: t('label.class'),
        onChange: value => changeFilter('classId', value)
      }
    },
    {
      label: t('label.tutor'),
      variant: 'multiselect',
      filterProps: {
        id: 'tutor',
        multiple: true,
        loading: isTeachersLoading,
        options: addUnassignedFilterOption(teachers?.options || []),
        value: tutor,
        placeholder: t('label.tutor'),
        onChange: value => changeFilter('tutor', value)
      }
    },
    {
      label: t('label.guardians'),
      variant: 'multiselect',
      filterProps: {
        id: 'parent',
        multiple: true,
        loading: isParentsLoading,
        options: addUnassignedFilterOption(parents?.options || []),
        value: parent,
        placeholder: t('label.guardians'),
        onChange: value => changeFilter('parent', value)
      }
    },
    {
      label: t('label.status'),
      variant: 'multiselect',
      filterProps: {
        id: 'status',
        multiple: true,
        loading: isStatusesLoading,
        options: statuses?.options || [],
        value: status,
        placeholder: t('label.status'),
        onChange: value => changeFilter('status', value)
      }
    }
  ]

  const isSomeFilterSelected = filters.some(filter => filter.filterProps.value)

  return (
    <>
      <Filters
        filters={filters}
        onClearAll={handleClearAll}
        disabledClearAllButton={!isSomeFilterSelected}
      />
      <Table
        id="students-table"
        isLoading={isStudentsLoading}
        actions={actions}
        rowSelection={
          user?.isSuperAdmin
            ? {
                selectedItems: selection.selectedItems,
                allCheckboxValue: selection.getSelectAllCheckboxValue(),
                count: students?.count || 0,
                labelHeader: t('label.select-all-students'),
                labelItem: (row: Student) =>
                  t('label.select-student', { STUDENT: row.fullName }),
                onSelect: selection.onSelect,
                onSelectAll: selection.onSelectAll
              }
            : undefined
        }
        data={students?.list || []}
        columns={tableColumns}
        pagination={{
          count: students?.count,
          pageSize,
          page
        }}
        onChangePageSize={handleChangePageSize}
        onChangePage={handleChangePage}
        tableWidth="100%"
        topBar={
          user?.isSuperAdmin ? (
            <>
              <div className={styles.headerTable}>
                {selection.selectedItems.length ? (
                  <Tooltip
                    renderTriggerAsChild
                    text={t('button.send-activation-link')}
                    trigger={
                      <div>
                        <ButtonIcon
                          variant="tertiary"
                          size="small"
                          onClick={() => {
                            setActivationLinkModalVariant('bulk')
                            setOpenActivationLinkModal(true)
                          }}
                          ariaLabel={t('button.send-activation-link')}
                        >
                          <SendLink />
                        </ButtonIcon>
                      </div>
                    }
                  />
                ) : null}
                <span className={styles.topBarText}>
                  {t('help.selected-text', {
                    SELECTED_COUNT: selection.selectedItems.length,
                    ALL_COUNT: allStudentCount || 0
                  })}
                </span>
              </div>
            </>
          ) : null
        }
      />

      <ActivationLinkModal
        variant={activationLinkModalVariant}
        ids={currentStudent?.id ? [currentStudent.id] : selection.selectedItems}
        studentName={currentStudent?.fullName || ''}
        open={openActivationLinkModal}
        onSuccess={() => {
          refetch()
          selection.clearSelection()
        }}
        onOpenChange={value => {
          setOpenActivationLinkModal(value)
          if (!value) setCurrentStudent(null)
        }}
      />

      <ConfirmModal
        id="blocked-modal"
        header={t('header.block-student')}
        subheader={t('help.want-to-block-student', {
          STUDENT: currentStudent?.fullName
        })}
        confirmButton={
          <Button variant="danger" onClick={handleBlock}>
            {t('button.block')}
          </Button>
        }
        open={openBlockConfirmModal}
        onOpenChange={setOpenBlockConfirmModal}
      />

      <ConfirmModal
        id="activate-modal"
        header={t('header.activate-student')}
        subheader={t('help.want-to-activate-student', {
          STUDENT: currentStudent?.fullName
        })}
        confirmButton={
          <Button onClick={handleActivate}>{t('button.activate')}</Button>
        }
        open={openActivateConfirmModal}
        onOpenChange={setOpenActivateConfirmModal}
      />
    </>
  )
}

export default StudentsView
