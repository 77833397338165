import * as Dialog from '@radix-ui/react-dialog'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@/assets/icons/close.svg?react'

import styles from './Modal.module.scss'
import ButtonIcon from '../ButtonIcon/ButtonIcon'
import Divider from '../Divider/Divider'

export type ModalSize = 'xs' | 'sm' | 'md' | 'lg'

type ModalProps = {
  id: string
  title: string
  open?: boolean
  size?: ModalSize
  contentClassName?: string
  trigger?: React.ReactNode
  children?: React.ReactNode
  description?: React.ReactNode
  onOpenChange?: (isOpen: boolean) => void
  onClose?: () => void
  footer?: React.ReactNode
}

const Modal = (props: ModalProps) => (
  <BaseModal
    trigger={props.trigger}
    id={props.id}
    open={props.open}
    description={props.description}
    contentClassName={props.contentClassName}
    title={props.title}
    size={props.size}
    onClose={props.onClose}
    onOpenChange={props.onOpenChange}
  >
    <div className={clsx(styles.box, !props.footer && styles.boxWithoutFooter)}>
      {props.children}
    </div>

    {props.footer ? <div className={styles.footer}> {props.footer}</div> : null}
  </BaseModal>
)

type BaseModalProps = {
  id: string
  title: string
  open?: boolean
  size?: ModalSize
  contentClassName?: string
  hideCloseIcon?: boolean
  trigger?: React.ReactNode
  children?: React.ReactNode
  description?: React.ReactNode
  onOpenChange?: (isOpen: boolean) => void
  onClose?: () => void
}

export const BaseModal = (props: BaseModalProps) => {
  const { size = 'sm', hideCloseIcon = false } = props
  const { t } = useTranslation('common')

  return (
    <Dialog.Root
      open={props.open}
      onOpenChange={value => {
        props.onOpenChange?.(value)
      }}
    >
      <Dialog.Trigger asChild>{props.trigger}</Dialog.Trigger>

      <Dialog.Portal>
        <Dialog.Overlay>
          <div className={styles.overlay} />
          <Dialog.Content
            id={props.id}
            aria-modal="true"
            aria-labelledby={`${props.id}-label`}
            className={clsx(
              styles.content,
              styles[size],
              props.contentClassName
            )}
            onEscapeKeyDown={props.onClose}
          >
            <div className={styles.titleWrapper}>
              <Dialog.Title className={styles.title} id={`${props.id}-label`}>
                {props.title}
              </Dialog.Title>

              {props.description ? (
                <p className={styles.description}>{props.description}</p>
              ) : null}
            </div>

            <Divider className={styles.divider} />
            {props.children}

            {!hideCloseIcon ? (
              <Dialog.Close asChild>
                <ButtonIcon
                  className={styles.closeIcon}
                  size="small"
                  ariaLabel={t('button.close')}
                  variant="tertiary"
                  onClick={event => {
                    if (props.onClose) {
                      event?.preventDefault()
                      props.onClose()
                    }
                  }}
                >
                  <CloseIcon />
                </ButtonIcon>
              </Dialog.Close>
            ) : null}
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default Modal
