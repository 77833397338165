import { zodResolver } from '@hookform/resolvers/zod'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

import Button from '@/components/Button/Button'
import FormField from '@/components/FormField'
import Input from '@/components/Input/Input'
import useExternalErrors from '@/hooks/useExternalErrors'
import type { FormError } from '@/types/form-error'
import { email } from '@/utils/zod'

import styles from './ResetPasswordForm.module.scss'

const formSchema = () =>
  z.object({
    email: email()
  })

type ResetPasswordPayload = z.infer<ReturnType<typeof formSchema>>

type ResetPasswordFormProps = {
  onSubmit: (payload: ResetPasswordPayload) => void
  errors?: FormError<ResetPasswordPayload>[]
  loading?: boolean
}

export const ResetPasswordForm = (props: ResetPasswordFormProps) => {
  const { t } = useTranslation(['auth'])
  const form = useForm<ResetPasswordPayload>({
    resolver: zodResolver(formSchema()),
    mode: 'all',
    defaultValues: {
      email: ''
    }
  })

  useExternalErrors(props.errors, form)

  return (
    <FormProvider {...form}>
      <form
        noValidate
        onSubmit={form.handleSubmit(props.onSubmit)}
        className={styles.formContainer}
      >
        <FormField
          control={form.control}
          id="email"
          required
          label={t('label.email')}
          name="email"
          render={({ inputProps }) => (
            <Input
              placeholder={t('label.email')}
              {...inputProps}
              type="email"
            />
          )}
        />

        <Button type="submit" block loading={props.loading}>
          {t('button.send')}
        </Button>
      </form>
    </FormProvider>
  )
}

export default ResetPasswordForm
