import { z } from 'zod'

import i18n from '@/i18n'

export const requiredString = () =>
  z
    .string()
    .min(1, { message: i18n.t('error.required-field', { ns: 'common' }) })

export const maxLength = (maxLenght: number) =>
  z.string().max(maxLenght, {
    message: i18n.t('error.max-lenght', { ns: 'common', NUMBER: maxLenght })
  })

export const email = () =>
  requiredString().email({
    message: i18n.t('error.invalid-email', { ns: 'common' })
  })

export const date = () => z.coerce.date()

export const arrayOfOptions = z.array(
  z.object({
    label: requiredString(),
    value: requiredString()
  })
)

export const userStatus = z.union([
  z.literal('active'),
  z.literal('inactive'),
  z.literal('blocked')
])

export const teacherStatus = z.union([
  z.literal('busy'),
  z.literal('available'),
  z.literal('unavailable')
])

export const absenceStatus = z.union([
  z.literal('excused'),
  z.literal('unexcused')
])

export const attendanceStatus = z.union([
  z.literal('present'),
  z.literal('late'),
  z.literal('absent_excused'),
  z.literal('absent_unexcused'),
  z.literal('')
])
