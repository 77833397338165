import clsx from 'clsx'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import EditFormIcon from '@/assets/icons/edit-form.svg?react'
import LessonCoverIcon from '@/assets/icons/lesson-cover.svg?react'
import ButtonIcon from '@/components/ButtonIcon/ButtonIcon'
import LinkText from '@/components/LinkText/LinkText'
import Modal from '@/components/Modal/Modal'
import TextWithLabel from '@/components/TextWithLabel/TextWithLabel'
import { toast } from '@/hooks/useToast'
import useAuthStore from '@/store/useAuthStore'
import { formatTime } from '@/utils/format-date'

import styles from './LessonDetails.module.scss'
import TopicAndNotesFormComponent from '../components/TopicAndNotesForm'
import useUpdateLesson from '../mutations/useUpdateLesson'
import type { Lesson } from '../types'
import { hasEditLessonPermission } from '../utils/permissions'

type LessonDetailsProps = {
  lessonId: string
  className?: string
  lesson: Lesson
  isMobile?: boolean
  refetchLessonDetails?: () => void
}

const LessonDetails = (props: LessonDetailsProps) => {
  const { t } = useTranslation(['lessonDetails'])
  const user = useAuthStore(store => store.user)

  const [isEditMode, setIsEditMode] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const {
    mutate: updateLesson,
    isPending: isLessonUpdating,
    formErrors: formErrors
  } = useUpdateLesson({
    onSuccess: () => {
      props.isMobile ? setIsModalOpen(false) : setIsEditMode(false)
      props.refetchLessonDetails?.()
      toast({
        title: t('toast.lesson-details-updated'),
        variant: 'success'
      })
    }
  })

  const canChangeLessonDetails = useMemo(
    () => hasEditLessonPermission(user, props.lesson),
    [user, props.lesson]
  )

  const TopicAndNotesForm = () => (
    <TopicAndNotesFormComponent
      onCancel={() => {
        setIsEditMode(false)
        setIsModalOpen(false)
      }}
      onSubmit={payload => {
        updateLesson({
          ...payload,
          id: props.lessonId
        })
      }}
      loading={isLessonUpdating}
      errors={formErrors}
      topic={props.lesson.topic}
      notes={props.lesson.notes}
    />
  )

  return (
    <div className={props.className}>
      <Details lesson={props.lesson} />

      <div className={clsx(styles.wrapper, props.className)}>
        {isEditMode ? (
          <TopicAndNotesForm />
        ) : (
          <>
            <div className={styles.wrapperTopic} data-test-id="topic-details">
              <div className={styles.wrapperTopicLabel}>
                <span className={styles.topicLabel}>{t('label.topic')}</span>
                {!props.isMobile && canChangeLessonDetails ? (
                  <ButtonIcon
                    variant="tertiary"
                    dataTestId="set-lesson-info"
                    onClick={() => setIsEditMode(true)}
                    size="small"
                    ariaLabel={t('button.edit-topic')}
                  >
                    <EditFormIcon />
                  </ButtonIcon>
                ) : null}
                {props.isMobile ? (
                  <div className={styles.mobileHeader}>
                    {canChangeLessonDetails ? (
                      <Modal
                        id="editTopicAndNotesModal"
                        title={t('header.edit-lesson-topic-and-notes')}
                        open={isModalOpen}
                        contentClassName={styles.editTopicAndNotesModal}
                        onOpenChange={setIsModalOpen}
                        trigger={
                          <ButtonIcon
                            ariaLabel={t('button.edit')}
                            variant="tertiary"
                            size="small"
                          >
                            <EditFormIcon />
                          </ButtonIcon>
                        }
                      >
                        <div className={styles.wrapper}>
                          <TopicAndNotesForm />
                        </div>
                      </Modal>
                    ) : null}
                  </div>
                ) : null}
              </div>
              <span className={styles.topicText}>
                {props.lesson.topic || '—'}
              </span>
            </div>
            <TextWithLabel
              size="large"
              label={t('label.notes')}
              dataTestId="notes-details"
              text={props.lesson?.notes}
            />
          </>
        )}
      </div>
    </div>
  )
}

type DetailsProps = {
  lesson: Lesson
}

const Details = (props: DetailsProps) => {
  const { t } = useTranslation(['lessonDetails'])

  return (
    <div className={styles.wrapperDetails}>
      <TextWithLabel
        label={t('label.teacher')}
        text={
          <div className={styles.teacherWrapper}>
            <LinkText
              to="/users/$userId/details"
              size="medium"
              params={{
                userId: props.lesson.teacher.id
              }}
            >
              <span
                className={clsx(
                  (props.lesson.isCancelled || props.lesson.teacherCover) &&
                    styles.detailsTextStrikethrough
                )}
              >
                {props.lesson.teacher.name}
              </span>
            </LinkText>

            {props.lesson.teacherCover ? (
              <>
                <LessonCoverIcon className={styles.coverIcon} />
                <LinkText
                  to="/users/$userId/details"
                  size="medium"
                  params={{
                    userId: props.lesson.teacherCover.id
                  }}
                >
                  <span
                    className={clsx(
                      props.lesson.isCancelled &&
                        styles.detailsTextStrikethrough
                    )}
                  >
                    {props.lesson.teacherCover.name}
                  </span>
                </LinkText>
              </>
            ) : null}
          </div>
        }
        variant="horizontal-full-row"
        dataTestId="course-teacher"
      />
      <TextWithLabel
        label={t('label.co-teacher')}
        variant="horizontal-full-row"
        text={
          props.lesson.coTeacher?.id ? (
            <div className={styles.teacherWrapper}>
              <LinkText
                size="medium"
                to="/users/$userId/details"
                params={{
                  userId: props.lesson.coTeacher?.id
                }}
              >
                <span
                  className={clsx(
                    (props.lesson.isCancelled || props.lesson.coTeacherCover) &&
                      styles.detailsTextStrikethrough
                  )}
                >
                  {props.lesson.coTeacher.name}
                </span>
              </LinkText>

              {props.lesson.coTeacherCover ? (
                <>
                  <LessonCoverIcon className={styles.coverIcon} />
                  <LinkText
                    to="/users/$userId/details"
                    size="medium"
                    params={{
                      userId: props.lesson.coTeacherCover.id
                    }}
                  >
                    <span
                      className={clsx(
                        props.lesson.isCancelled &&
                          styles.detailsTextStrikethrough
                      )}
                    >
                      {props.lesson.coTeacherCover.name}
                    </span>
                  </LinkText>
                </>
              ) : null}
            </div>
          ) : (
            '—'
          )
        }
      />

      <TextWithLabel
        label={t('label.time')}
        dataTestId="course-time"
        variant="horizontal-full-row"
        text={
          <span
            className={clsx(
              props.lesson.isCancelled && styles.detailsTextStrikethrough
            )}
          >
            {`${formatTime(props.lesson.startDate)} - ${formatTime(props.lesson.endDate)}`}
          </span>
        }
      />
      <TextWithLabel
        label={t('label.room')}
        text={
          props.lesson?.room?.name ? (
            <span
              className={clsx(
                props.lesson.isCancelled && styles.detailsTextStrikethrough
              )}
            >
              {props.lesson.room.name}
            </span>
          ) : null
        }
        variant="horizontal-full-row"
        dataTestId="class-room"
      />
    </div>
  )
}

export default LessonDetails
